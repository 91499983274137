import $ from 'jquery'
import { SimpleroManager, registerManager } from '~/common/extensions/dom_ready'
import SimpleroPusher from '~/common/helpers/simplero_pusher'
import { AjaxHelper } from '~/common/extensions/ajax_helpers'

class PusherWaiter extends SimpleroManager {
  constructor(elm, name, options) {
    super(elm, name)

    let left, left1, left2
    this.triggerCallback = this.triggerCallback.bind(this)
    this.onTimeout = this.onTimeout.bind(this)
    this.onSuccess = this.onSuccess.bind(this)

    this.options = options != null ? options : {}
    this.channel =
      this.options.channel != null
        ? this.options.channel
        : this.elm.attr('data-channel')
    this.event =
      this.options.event != null
        ? this.options.event
        : this.elm.attr('data-event')
    this.timeout =
      (left =
        this.options.timeout != null
          ? this.options.timeout
          : this.elm.data('timeout')) != null
        ? left
        : 5
    this.oneShot =
      (left1 =
        this.options.oneShot != null
          ? this.options.oneShot
          : this.elm.data('one-shot')) != null
        ? left1
        : false
    this.backlog =
      (left2 =
        this.options.backlog != null
          ? this.options.backlog
          : this.elm.data('backlog')) != null
        ? left2
        : true
    this.timer = setTimeout(this.onTimeout, this.timeout * 1000)
    this.fired = false

    SimpleroPusher.bind(this.channel, this.event, this.onSuccess, this.backlog)
  }

  triggerCallback(callback, data) {
    if (this.options[callback] != null) {
      this.options[callback].call()
    }
    if (this.options.complete != null) {
      this.options.complete.call()
    }
    this.elm.trigger(`waiter:${callback}`, data)
    return this.elm.trigger('waiter:complete', data)
  }

  onTimeout() {
    this.timer = null
    return this.triggerCallback('timeout')
  }

  onSuccess(data) {
    if (this.timer != null) {
      clearTimeout(this.timer)
    }
    this.timer = null
    const firstShot = !this.fired
    this.fired = true

    if (this.oneShot && !firstShot) {
      return
    }

    // Run any callbacks
    this.triggerCallback('success', data)

    // If the wait block includes a data-url attribute, request that URL now
    if (this.elm.data('url') != null) {
      $.get(this.elm.data('url'), (data, _status, _xhr) =>
        AjaxHelper.doUpdate(data)
      )
    }

    // If the event payload includes a redirect_to attribute, redirect to that URL
    if (data.redirect_to != null) {
      window.location.href = data.redirect_to
    }

    if (data.refresh_table != null) {
      return AjaxHelper.doUpdate({ refresh_table: data.refresh_table })
    }

    if (data.notice != null) {
      AjaxHelper.doUpdate({ notice: data.notice })
    }
  }
}

$.fn.pusherWaiter = function (options) {
  return this.each(function () {
    return new PusherWaiter(this, 'PusherWaiter', options)
  })
}

registerManager(PusherWaiter, 'PusherWaiter', '[data-behavior~=wait]')
